import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store/store'
import reportWebVitals from './reportWebVitals'
import ThemeProvider from './app/components/ThemeProvieder'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Layout from './Layout'

const router = createBrowserRouter([
  {
    path: '/assignment',
    lazy: async () => {
      const Assignment = await import('app/elements/Assignment')
      return { Component: Assignment.default }
    },
  },
  {
    path: '/assigment',
    lazy: async () => {
      const Assignment = await import('app/elements/Assignment')
      return { Component: Assignment.default }
    },
  },
  {
    path: '/element',
    lazy: async () => {
      const Element = await import('app/elements/Element')
      return { Component: Element.default }
    },
  },
  {
    path: '/embed',
    lazy: async () => {
      const Embed = await import('app/elements/Embed')
      return { Component: Embed.default }
    },
  },
  {
    path: '/recordings',
    lazy: async () => {
      const Recordings = await import('app/elements/Recordings')
      return { Component: Recordings.default }
    },
  },
  {
    path: '/feedback',
    lazy: async () => {
      const Feedback = await import('app/elements/Feedback')
      return { Component: Feedback.default }
    },
  },
  {
    path: '/exam/:assignmentId/edit',
    lazy: async () => {
      const EditExam = await import('app/elements/EditExam')
      return { Component: EditExam.default }
    },
  },
  {
    path: '/drag-n-drop/:assignmentId/edit',
    lazy: async () => {
      const DragDropEdit = await import('app/elements/DragDrop/DragDropEdit')
      return { Component: DragDropEdit.default }
    },
  },
  {
    path: '/forum/:assignmentId/edit',
    lazy: async () => {
      const EditForum = await import(
        'app/components/assigments/Forum/EditForum'
      )
      return { Component: EditForum.default }
    },
  },
  {
    path: '/code',
    lazy: async () => {
      const { CodeRunner } = await import('app/components/assigments/Coding')
      return { Component: CodeRunner }
    },
  },
  {
    path: '/feedbackView',
    lazy: async () => {
      const FeedbackView = await import('app/elements/FeedbackView')
      return { Component: FeedbackView.default }
    },
  },
  {
    path: '/attendance',
    lazy: async () => {
      const AttendanceV2 = await import(
        'app/elements/AttendanceV2/AttendanceV2'
      )
      return { Component: AttendanceV2.default }
    },
  },
  {
    path: '/attendance/:id',
    lazy: async () => {
      const Session = await import('app/elements/AttendanceV2/Session')
      return { Component: Session.default }
    },
  },
  {
    path: '/attendance/:id/complete',
    lazy: async () => {
      const AttendanceSuccess = await import(
        'app/elements/AttendanceV2/AttendanceSuccess'
      )
      return { Component: AttendanceSuccess.default }
    },
  },
  {
    path: '/survey',
    lazy: async () => {
      const Survey = await import('app/elements/Survey')
      return { Component: Survey.default }
    },
  },
])

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </ThemeProvider>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
