// GET ASSIGNMENT BY ID
export const getAssignmentById = (builder) => {
  return builder.query({
    query: ({ id, activeOnly, showCode }) => ({
      url: `/assignments.info`,
      method: 'POST',
      body: {
        id,
        activeOnly,
        showCode,
      },
    }),
    transformResponse: (response, _, arg) => {
      if (arg.showCode) {
        const assignmentData = response.data
        response.data.cleanTitle = assignmentData.title

        if (assignmentData.code && assignmentData.code.length > 0) {
          response.data.title = `${assignmentData.code}: ${assignmentData.title}`
        }
      }
      return response.data
    },
    providesTags: (result, error, { id }) => [{ type: 'Assignment', id }],
  })
}

// GET ASSIGNMENT TYPES
export const getAssignmentTypes = (builder) => {
  return builder.query({
    query: () => ({
      url: '/assignmentTypes.list',
      method: 'POST',
    }),
  })
}

// CREATE ASSIGNMENT
export const createAssignment = (builder) => {
  return builder.mutation({
    query: ({ assignment }) => ({
      url: '/assignments.create',
      method: 'POST',
      body: assignment,
    }),
    invalidatesTags: (result, error, { id }) => [{ type: 'Assignment', id }],
  })
}

// UPDATE ASSIGNMENT
export const updateAssignment = (builder) => {
  return builder.mutation({
    query: ({ id, regrade, ...rest }) => ({
      url: '/assignments.update',
      method: 'POST',
      body: {
        id,
        regrade,
        ...rest,
      },
    }),
    transformResponse: (response, _, arg) => {
      if (arg.showCode) {
        const assignmentData = response.data
        response.data.cleanTitle = assignmentData.title

        if (assignmentData.code && assignmentData.code.length > 0) {
          response.data.title = `${assignmentData.code}: ${assignmentData.title}`
        }
      }
      return response.data
    },
    invalidatesTags: (result, error, { id }) => [
      { type: 'Assignment', id },
      { type: 'Submission', assignmentId: id },
    ],
  })
}

// TOGGLE IS OPEN
export const toggleOpen = (builder) => {
  return builder.mutation({
    query: ({ id, isOpen }) => ({
      url: '/assignments.toggleOpen',
      method: 'POST',
      body: {
        id,
        isOpen,
      },
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: (result, error, { id }) => [{ type: 'Assignment', id }],
  })
}

export default {
  getAssignmentById,
  getAssignmentTypes,
  createAssignment,
  updateAssignment,
  toggleOpen,
}
